import React, { Component } from "react";
import {Col} from "react-bootstrap";
import angklungImg from "./angklung.svg";

class Angklung extends Component{
    constructor(props){
        super(props);
        this.state = {
          play: false
        }
    }
    componentDidMount() {
        this.url = "/music/long_"+this.props.nada+".mp3";
        this.audio = new Audio(this.url);
    }

    play = () => {
        if(!this.state.play){
            this.audio.play();
            this.setState({play:true});
        }
    }

    pause = () => {
        this.setState({play:false});
    }



    render() {
        return(
           <Col className="angklung-col">
              <img
                  onMouseEnter={this.play}
                  onMouseLeave={this.pause}
                  className="angklung" src={angklungImg}/>
                  <h1>{this.props.nama}</h1>
          </Col>
        );
    }

}

export default Angklung;