import React,{Component} from 'react';
import {Modal} from 'react-bootstrap';
import logo from "./logo.png";

class TentangKami extends Component{

    render(){
        return(
          <Modal show={this.props.show} onHide={this.props.handler} >
              <Modal.Body className="menu modalTtg" >
                 <img src={logo} className="mb-5" />
                 <p style={{textIndent:"20px"}}>
                     Aplikasi ini kami tujukan untuk membantu anak-anak di Indonesia
                     agar dapat mengenal serta melestarikan budaya musik dari daerah di Indonesia.
                 </p>
                  <p style={{textIndent:"20px"}}>

                     Karena kami sadari bahwa banyak manfaat yang dapat diambil dari mengenal kebudayaan musik tradisional Indonesia.
                     Mengenal kebudayaan asli daerah Indonesia dapat meningkatkan rasa toleransi serta nasionalisme pada anak.
                   </p>
                  <p style={{textIndent:"20px"}}>
                     Ucapan terimakasih kami sampaikan kepada Ibu Paujiatul Arifah, Kelas 2KA21, Universitas Gunadarma, Google, Youtube, Wikipedia
                     serta pihak-pihak lain yang turut membantu dalam pengembangan aplikasi ini

                    <br/><br/>
                      Salam kami,<br/>
                      Haviansyah, Rizky Amalia, Rizka Alviena & Yudith Puspa
                  </p>
              </Modal.Body>

            </Modal>
        );
    }
}

export default TentangKami;