import React, { Component } from "react";
import logo from "./logo.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import angklungimg from './angklung-icon.svg';
import { Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faDrum, faMusic, faQuestion} from "@fortawesome/free-solid-svg-icons";
import TentangKami from "./TentangKami";
import {Button} from "react-bootstrap";


class Menu extends Component {

    constructor(props){
        super(props);
        this.state ={
            showTtg : false,
        }
    }

    handleTtg = () =>{
            if(this.state.showTtg){
                this.setState({showTtg:false})
            }else{
                this.setState({showTtg:true})
            }
    }

    render() {
        return(
        <div className="menu">
            <img src={logo} className="mb-5" />
            <Row>
                <Col>
                    <Link to="/alat" className="btn btn-menu">
                        <FontAwesomeIcon icon={faDrum} size="4x"/>
                        <p>Alat Musik </p>
                    </Link>
                </Col>
                 <Col>
                    <Link to="/lagu" className="btn btn-menu">
                        <FontAwesomeIcon icon={faMusic} size="4x"/>
                        <p>Lagu Daerah</p>
                    </Link>
                </Col>
                <Col>
                    <Link to="/main" className="btn btn-menu">
                        <img src={angklungimg} width="75px"/>
                        <p>Main Angklung</p>
                    </Link>
                </Col>
                 <Col>
                    <Button onClick={this.handleTtg} className="btn btn-menu">
                        <FontAwesomeIcon icon={faQuestion} size="4x"/>
                        <p>Tentang Kami</p>
                    </Button>
                </Col>
            </Row>
            <TentangKami show={this.state.showTtg} handler={this.handleTtg} />

        </div>
        );
    }

}
export default Menu;