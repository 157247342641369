import React, {Component} from 'react';
import Slider from "react-slick";
import dataAlat from './data-alat.json';
import { Link} from "react-router-dom";

class AlatMusik extends Component{
    constructor(props){
        super(props);
        this.alats = dataAlat.alats;
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
     next() {
        this.slider.slickNext();
      }
      previous() {
        this.slider.slickPrev();
      }

      wheel(e){
        e.preventDefault();
        if (e.deltaY  < 0) {
            this.next();
        }else{
            this.previous();
        }
      }
    render() {
    var settings = {
      centerMode: true,
      infinite: true,
      slidesToShow: 3,
      speed: 500
    };
    return (
        <div onWheel ={(e) => this.wheel(e)}  style={{width:"1000px"}}>
        <Slider  ref={c => (this.slider = c)}   {...settings}>
            {this.alats.map((item,i) => {
                    return(
                         <Link to={"/alat/"+i} className="alats text-center">
                              <h2>{item.nama}</h2>
                            <img src={item.gambar}/>
                          </Link>
                    );
                }
            )}

        </Slider>
        </div>

    );
  }
}

export default AlatMusik;