import React, {Component} from 'react';
import Slider from "react-slick";
import dataLagu from './data-lagu.json';
import { Link} from "react-router-dom";
import TentangKami from "./TentangKami";
import DetailLagu from "./DetailLagu";


class Lagu extends Component{
    constructor(props){
        super(props);
        this.lagus = dataLagu.lagus;
         this.state ={
             showModal : false,
             judul : "",
             asal : "",
             info : "",
        }

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    handleModal = (lagu) =>{
        this.setState({
            judul:lagu.judul,
            asal : lagu.asal,
            info : lagu.info,
            showModal:true
        });
    }

    handleClose = () =>{
        this.setState({showModal:false});
    }

     next() {
        this.slider.slickNext();
      }
      previous() {
        this.slider.slickPrev();
      }

      wheel(e){
        e.preventDefault();
        if (e.deltaY  < 0) {
            this.next();
        }else{
            this.previous();
        }
      }
    render() {
    var settings = {
      centerMode: true,
      infinite: true,
      slidesToShow: 1,
      speed: 500
    };
    return (
        <div onWheel ={(e) => this.wheel(e)}  style={{width:"1000px"}}>
        <Slider  ref={c => (this.slider = c)}   {...settings}>
            {this.lagus.map((item,i) => {
                    return(
                         <a  onClick={() => this.handleModal(item)} to={"/alat/"+i} className="alats text-center">
                              <h2>{item.judul}</h2>
                              <iframe width="500" height="300" scrolling="no"  src={item.video}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                          </a>
                    );
                }
            )}

        </Slider>
        <DetailLagu judul={this.state.judul} asal={this.state.asal} info={this.state.info} show={this.state.showModal} handler={this.handleClose}  />
        </div>


    );
  }
}

export default Lagu;