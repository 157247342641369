import React, {Component} from 'react';
import {Row, Col,Card} from 'react-bootstrap';
import dataAlat from './data-alat.json';

class DetailAlatMusik extends Component{
     constructor(props) {
         super(props);
         this.state = {
            nama: "",
            asal: "",
            video : "",
            gambar : "",
            info : ""
        }
         this.alat = dataAlat;
   }
    componentDidMount() {
        const id = this.props.match.params.id;
        const alat = this.alat.alats[id];
        this.setState({
            nama : alat.nama,
            asal : alat.asal,
            video : alat.video,
            gambar : alat.gambar,
            info : alat.info,
        });
     }


    render(){
        return(
            <div className="detailAlat">
                <Row>
                    <Col md="4" style={{position:"fixed"}}>
                        <Card className="card-info">
                            <Card.Body>
                                <iframe width="380" height="300" scrolling="no"  src={this.state.video}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen></iframe>
                                <p style={{fontSize:"0.5em"}}>Sumber : {this.state.video}</p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="7" className="offset-md-5" >
                        <Card className="card-info">
                            <Card.Body >
                                <div className="infoAlat">
                                    <h1>{this.state.nama}</h1>
                                    <p>
                                        Asal : {this.state.asal}
                                    </p>
                                    <img className="img-fluid" src={this.state.gambar}/>

                                    <p>
                                        {this.state.info}
                                    </p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default DetailAlatMusik;