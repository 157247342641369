import React,{Component} from 'react';
import {Modal} from 'react-bootstrap';
import logo from "./logo.png";

class DetailLagu extends Component{
    constructor(props){
        super(props);
        this.judul = this.props.judul;
    }

    render(){
        return(
          <Modal show={this.props.show} onHide={this.props.handler} >
                  <Modal.Body className="menu modalLagu">
                      <h1>{this.props.judul}</h1>
                      <h2>Asal : {this.props.asal}</h2>
                      <p style={{textIndent: "20px"}}>
                          {this.props.info}
                      </p>

                  </Modal.Body>
            </Modal>
        );
    }
}

export default DetailLagu;