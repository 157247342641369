import React, { Component } from "react";
import {Row} from "react-bootstrap";
import Angklung from './Angklung';

class MainAngklung extends Component {

    componentDidMount() {
        this.props.stopMusik();
    }
    render() {
        return(
          <div className="menu">
              <h1 className="mb-5">Main Angklung</h1>
              <Row>
                <Angklung nada="do1" nama="Do"/>
                <Angklung nada="re" nama="Re"/>
                <Angklung nada="mi" nama="Mi"/>
                <Angklung nada="fa" nama="Fa"/>
                <Angklung nada="sol" nama="Sol"/>
                <Angklung nada="la" nama="La"/>
                <Angklung nada="si" nama="Si"/>
                <Angklung nada="do2" nama="Do"/>

              </Row>
          </div>
        );
    }
}
export default MainAngklung;