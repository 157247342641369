import React, { Component } from 'react';


import './App.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlay,faPause } from '@fortawesome/free-solid-svg-icons'
import { Button, Navbar, Nav, Form } from 'react-bootstrap';
import {faHome} from "@fortawesome/free-solid-svg-icons/faHome";
import Menu from './Menu';
import MainAngklung from './MainAngklung';
import DetailAlatMusik from './DetailAlatMusik';
import AlatMusik from "./AlatMusik";
import Lagu from "./Lagu";

class App extends Component{
   constructor(props) {
        super(props);
        this.state = {
          play: false,
          pause: true,
        }
        this.url = "/music/lagu-indonesia.mp3";
        this.audio = new Audio(this.url);
   }

   componentDidMount() {
        this.play();
   }
   play = () => {
        this.setState({ play: true, pause: false })
        this.audio.play();
   }

   pause = () => {
        this.setState({ play: false, pause: true })
        this.audio.pause();
    }
    toggle = () =>{
       if(this.state.play){
           this.pause();
       }else{
           this.play();
       }
    }

  render() {
       const isPlayed = this.state.play;
    return (
         <Router>
            <div className="App">
                <Navbar scrolling light expand="md" fixed="top">
                    <Link to='/' className='navbar-brand'><Button variant="outline-light"><FontAwesomeIcon icon={faHome}/></Button></Link>
                    <Nav className="mr-auto">
                      {/*<Nav.Link href="#home">Home</Nav.Link>*/}
                      {/*<Nav.Link href="#features">Features</Nav.Link>*/}
                      {/*<Nav.Link href="#pricing">Pricing</Nav.Link>*/}
                    </Nav>
                    <Form inline>
                      {/*<FormControl type="text" placeholder="Search" className="mr-sm-2" />*/}
                      <Button onClick={this.toggle} variant="outline-light"><FontAwesomeIcon icon={isPlayed ? faPause : faPlay}/></Button>
                    </Form>
                </Navbar>
              <header className="App-header">

                    <Route path="/" exact component={Menu} />
                    <Route path="/lagu" exact component={Lagu} />
                    <Route path="/alat" exact component={AlatMusik} />
                    <Route exact path="/alat/:id"
                     render={(props) => <DetailAlatMusik  {...props} /> } />
                    <Route path="/main" render={
                        props => <MainAngklung{...props} stopMusik={this.pause}/>
                    } />
              </header>
            </div>
         </Router>
    );
  }
}

export default App;
